import { useQuery } from "@apollo/client";
import GET_MY_RECORDINGS from "../queries/getMyRecordings";
import AudioTrackListComponent from "../components/AudioTrackList";

function AudioTrackList() {
  const { data } = useQuery(GET_MY_RECORDINGS);
  const tracks = data?.myRecordings?.recordings;
  return <AudioTrackListComponent tracks={tracks} />;
}

export default AudioTrackList;

import AudioPlayer from "./AudioPlayer";

const AudioTrackItem = ({ track, chosenTrack, setChosenTrack }) => {
  return (
    <li key={track.uploadTime} className="pt-3 pb-1">
      <div className="flex items-center space-x-4 px-1">
        <div className="min-w-0 flex-1">
          <div className="flex space-x-4">
            <p className="truncate text-sm font-medium text-gray-900">
              {`${track.uploadTime}`}
            </p>
            <p className="truncate text-sm font-light text-gray-900">
              {`${track.id}`}
            </p>
          </div>
          <p className="text-sm text-gray-700 mx-4">
            {track.text ? track.text : "No Transcript Available"}
          </p>
        </div>
        <div
          onClick={() => setChosenTrack(track)}
          className="inline-flex items-center rounded-full border border-gray-300 bg-white px-3 py-1 text-sm font-medium leading-5 text-gray-700 shadow-sm hover:bg-gray-50 cursor-pointer"
        >
          Play
        </div>
      </div>
      {chosenTrack && chosenTrack === track && (
        <div className="mt-1">
          <AudioPlayer track={track} />
        </div>
      )}
    </li>
  );
};

export default AudioTrackItem;

import Footer from "./Footer";
import Header from "./Header";
import MediaUploadContainer from "../containers/MediaUpload";
import AudioTrackListContainer from "../containers/AudioTrackList";

export default function App() {
  return (
    <div className="min-h-full">
      <Header />
      <main>
        <section className="my-6">
          <AudioTrackListContainer />
        </section>
        <section>
          <MediaUploadContainer />
        </section>
      </main>
      <Footer />
    </div>
  );
}

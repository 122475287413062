import gql from "graphql-tag";

const REQUEST_MEDIA_UPLOAD = gql`
  mutation requestMediaUpload($uploadParams: RequestUploadInput!) {
    requestMediaUpload(uploadParams: $uploadParams) {
      recordingId
      uploadUrl
    }
  }
`;

export default REQUEST_MEDIA_UPLOAD;

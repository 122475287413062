import React from "react";
import propTypes from "prop-types";
import { ApolloProvider } from "@apollo/client";
import apolloClient from "../../services/apollo";

const ProviderWrapper = ({ children }) => {

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

ProviderWrapper.propTypes = {
  children: propTypes.node.isRequired,
};

export default ProviderWrapper;

import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import axios from "axios";

import MediaUploadComponent from "../components/MediaUpload";

import REQUEST_MEDIA_UPLOAD from "../mutations/requestMediaUpload";

const IncidentCommentsContainer = () => {
  const [uploadingMedia, setUploadingMedia] = useState(false);

  const [requestMediaUpload, { loading: mutationLoading, error: mutationError }] = useMutation(
    REQUEST_MEDIA_UPLOAD,
    {
      onCompleted: () => {
        setUploadingMedia(false);
      },
      onError: (err) => {
        setUploadingMedia(false);
      },
    }
  );

  const handleMediaUpload = async (file, md5Hash, setFinished) => {
    setUploadingMedia(true);
    requestMediaUpload({
      variables: {
        uploadParams: {
          contentType: "audio/mp4",
          contentMD5: md5Hash,
          metadata: [
            {
              key: "type",
              value: "test",
            },
          ],
        },
      },
      onCompleted: async (response) => {
        await axios.put(
          response.requestMediaUpload.uploadUrl,
          file,
          {
            headers: {
              "Content-MD5": md5Hash,
            },
          }
        );
        setFinished();
      },
    });
  };
  return (
    <>
      {mutationLoading && <div>loading</div>}
      {mutationError && <div>error</div>}
      <MediaUploadComponent
        uploading={uploadingMedia}
        handleMediaUpload={handleMediaUpload}
      />
    </>
  );
};

IncidentCommentsContainer.propTypes = {
};

export default IncidentCommentsContainer;

import { useState } from "react";
import CryptoJS from 'crypto-js';

export default function Upload({handleMediaUpload}) {
  const [uploading, setIsUploading] = useState(false);
  const [fileType, setFileType] = useState("");
  const [fileHash, setFileHash] = useState('');
  const [file, setFile] = useState("");

  const handleSetFile = (input) => {
    let file = input.files[0];
    let reader = new FileReader();
    reader.onload = function(steve) {
      const digest = CryptoJS.MD5(
        CryptoJS.lib.WordArray.create(reader.result)
      ).toString(CryptoJS.enc.Base64);
      setFileHash(digest);
    };
    reader.readAsArrayBuffer(file);

    reader.onerror = function() {
      console.log(reader.error);
    };
  }

  const handleSubmitUploadMedia = (e) => {
    e.preventDefault();
    if (fileType === "") {
      return;
    }
    setIsUploading(true)
    handleMediaUpload(file, fileHash, () => setIsUploading(false));
    setFileType("");
  };
  return (
    <div className="my-6 mx-4">
      {fileType === "" ? (
        <div className="border border-dashed border-black border-opacity-25 relative rounded-md bg-slate-50">
          <input
            aria-label="Media Upload"
            data-testid="upload-media-input"
            type="file"
            accept="audio/*"
            className="cursor-pointer relative block opacity-0 w-full h-full p-12 z-10"
            onChange={(e) => {
              handleSetFile(e.target);
              setFile(e.target.files[0]);
              setFileType(e.target.files[0].type);
            }}
          />
          <div className="text-center p-6 absolute top-0 right-0 left-0 m-auto">
            {uploading ? (
              <div class="flex justify-center items-center">
                <div
                  class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                  role="status"
                >
                  <span class="hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                <p>
                  Upload a file
                  <br />
                  or
                </p>
                <p>Drag and Drop</p>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="relative border border-dashed border-black border-opacity-25 rounded-md bg-slate-50">
          <button
            type="button"
            onClick={() => setFileType("")}
            className="absolute top-0 right-0 m-2"
          >
            Cancel
          </button>
          <div className="lg:flex lg:justify-around items-center text-center lg:m-4">
            <div className="m-auto h-24">
              <div className="m-auto">{file.name}</div>
            </div>
          </div>
        </div>
      )}
      <div className="w-full flex justify-end">
        <button
          type="submit"
          disabled={uploading || !file}
          onClick={handleSubmitUploadMedia}
          aria-label="upload-media"
          className="mt-5 bg-green-300 rounded-sm hover:bg-green-200 font-semibold py-2 px-6 border border-black border-opacity-10 uppercase tracking-wider"
        >
          upload
        </button>
      </div>
    </div>
  );
}

import { useState } from "react";
import AudioTrackItem from "./AudioTrackItem";

const AudioTrackList = ({ tracks = [] }) => {
  const [chosenTrack, setChosenTrack] = useState("")
  return (
    <div>
      <div className="mt-6 flow-root">
        <ul className="divide-y divide-gray-200 h-[600px] overflow-y-auto">
          {tracks.map((track) => (
            <AudioTrackItem track={track} chosenTrack={chosenTrack} setChosenTrack={setChosenTrack} />
          ))}
        </ul>
      </div>
      {/* <div className="mt-6">
        <a
          href="#"
          className="flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
        >
          View More
        </a>
      </div> */}
    </div>
  );
}

export default AudioTrackList;
